<template>
  <section
    class="news-wrap"
    @click="toDetail"
  >
    <img
      v-if="!detail.firstPic"
      src="@/assets/images/public/notPic.jpg"
      alt=""
    >
    <img
      v-else
      :src="detail.firstPic"
    />
    <article class="news-detail">
      <h3 v-html="detail.title"></h3>
      <p v-html="detail.hexintishi"></p>
      <p class="news-time">{{detail.newsTime}}</p>
    </article>
  </section>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  props: [
    'detail',
    'title',
    'search'
  ],
  components: {
  },
  created () {
    if (this.search) {
      this.detail.title = this.detail.title.replace(this.search, "<span style='color:#EC3E3E'>" + this.search + '</span>')
      this.detail.hexintishi = this.detail.hexintishi.replace(this.search, "<span style='color:#EC3E3E'>" + this.search + '</span>')
    }
  },
  methods: {
    toDetail () {
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: encodeURIComponent(this.title),
          newsId: this.detail.newsId
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.news-wrap
  display flex
  padding 25px 20px
  border-bottom 1px solid #424242
  cursor pointer
  &:hover
    background-color #1e1e23
  img
    width 132px
    height 88px
    border-radius 3px
  .news-detail
    margin-left 20px
    flex 1
    h3
      font-size 16px
      font-weight 500
      color #ffffff
      line-height 22px
      margin-bottom 6px
    p
      color #999999
      font-size 12px
      line-height 18px
      height 37px
      display -webkit-box
      -webkit-box-orient vertical
      -webkit-line-clamp 2
      overflow hidden
    .news-time
      height auto
</style>
