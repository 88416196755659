<template>
  <div
    class="brand-tab"
    v-loading="loading"
  >
    <div class="brand-tab-item">
      <span
        :class="[type==='NewPower'?'active':'']"
        @click="selectLabel('NewPower')"
      >星势力品牌</span>
      <span
        :class="[type==='NewBrand'?'active':'']"
        @click="selectLabel('NewBrand')"
      >新兴品牌</span>
      <span
        :class="[type==='LeadingBrand'?'active':'']"
        @click="selectLabel('LeadingBrand')"
      >人气品牌</span>
      <span
        :class="[type==='PotentialBrand'?'active':'']"
        @click="selectLabel('PotentialBrand')"
      >潜力品牌</span>
    </div>
    <ul class="brand-tab-list">
      <li
        v-for="item in list"
        :key="item.newsId"
      >
        <div
          class="brand-logo"
          @click="toBrandDetail(item)"
        >
          <img
            v-if="!item.brandLogo"
            src="@/assets/images/public/notPic.jpg"
            alt=""
          >
          <img
            v-else
            :src="item.brandLogo"
          />
          <i
            class="star-tag"
            v-if="type==='NewPower'"
          ></i>
        </div>
        <div class="brand-info">
          <h3 @click="toBrandDetail(item)">{{item.brandName}}</h3>
          <p @click="toNewsDetail(item)">{{item.title}}</p>
        </div>
      </li>
    </ul>
    <div class="see-more">
      <span @click="toBrandList">查看更多</span>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      type: 'NewPower',
      list: [],
      loading: false
    }
  },
  components: {
  },
  created () {
    this.loading = true
    this.getBrandData()
  },
  methods: {
    getBrandData () {
      switch (this.type) {
        case 'NewPower':
          this.getList(api.getNewsBrandStar)
          break
        case 'NewBrand':
          this.getList(api.getNewsBrandNew)
          break
        case 'LeadingBrand':
          this.getList(api.getNewsBrandAttractive)
          break
        case 'PotentialBrand':
          this.getList(api.getNewsBrandPotential)
          break
        default:
          break
      }
    },
    getList (api) {
      this.axios.post(api)
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            this.list = res.data.data
          }
        })
    },
    selectLabel (type) {
      this.type = type
      this.loading = true
      this.getBrandData()
    },
    toNewsDetail (detail) {
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: encodeURIComponent('商业洞察列表'),
          newsId: detail.newsId
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    },
    toBrandDetail (detail) {
      const page = this.$router.resolve({
        path: '/brandDetail',
        query: {
          id: detail.brandId,
          source: 1,
          gbid: detail.gbid,
          questionState: 0
        }
      })
      window.open('/' + page.href, '_blank')
    },
    toBrandList (detail) {
      const page = this.$router.resolve({
        path: '/selfBrandList',
        query: {
          source: 1,
          from: this.type
        }
      })
      window.open('/' + page.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.brand-tab
  background-color #272930
.brand-tab-item
  border-bottom 1px solid #424242
  display flex
  padding 0 16px
  span
    flex auto
    display inline-block
    cursor pointer
    line-height 54px
    margin 0 10px
    text-align center
    font-weight 400
    color rgba(255, 255, 255, 0.7)
    border-bottom 4px solid transparent
    transition all 0.4s
    &.active
      border-bottom 4px solid #FFA134
      color #ffffff
      font-weight 500
    &:hover
      border-bottom 4px solid #FFA134
      font-weight 500
.star-tag
  position absolute
  left -9px
  top -12px
  width 20px
  height 27px
  background url('~@/assets/images/public/star-label.png') no-repeat
  background-size 100%
  z-index 111
.brand-tab-list
  li
    display flex
    align-items center
    border-bottom 1px solid #424242
    padding 20px
    transition all 0.3s
    &:hover
      background-color #1e1e23
.brand-logo
  width 75px
  height 55px
  margin-right 13px
  cursor pointer
  position relative
  img
    width 100%
    height 55px
.brand-info
  h3
    font-size 16px
    color #ffffff
    line-height 20px
    margin-bottom 5px
    cursor pointer
  p
    color #999
    font-size 12px
    line-height 17px
    cursor pointer
.see-more
  font-size 15px
  line-height 55px
  text-align center
  color #999
  span
    cursor pointer
</style>
