<template>
  <div class="brand-top" v-loading="loading">
      <div class="brand-title">
        <h2 class="tendency-title"><i></i>品牌榜单</h2>
        <div class="see-more pointer" @click="toBrandTopDetail(topList[0])">查看更多</div>
      </div>
      <article
        class="brand-top-content pointer"
        v-for="(item, index) in topList.slice(0,2)"
        :key="index"
        @click="toBrandTopDetail(item)">
        <h3>{{item.title}}</h3>
        <p>
          {{item.introduction}}
        </p>
      </article>
      <div class="brand-top-list">
        <div class="list-item bg-left pointer" @click="toBrandTopDetail(topList[2])">
          <span>{{topList[2] && topList[2].title}}</span>
        </div>
        <div>
          <div class="list-item h-120 bg-right-one pointer" @click="toBrandTopDetail(topList[3])"><span>{{topList[3] && topList[3].title}}</span></div>
          <div class="list-item h-120 bg-right-two pointer" @click="toBrandTopDetail(topList[4])"><span>{{topList[4]&& topList[4].title}}</span></div>
        </div>
      </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      topList: [],
      loading: false
    }
  },
  components: {
  },
  created () {
    this.loading = true
    this.getTopList()
  },
  methods: {
    getTopList () {
      this.axios.post(api.getNewsBrandTopList, {
        yearName: ''
      })
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            this.topList = res.data.data.reverse()
          }
        })
    },
    toBrandTopDetail (detail) {
      let query = {}
      if (detail) {
        query = {
          topId: detail.topId,
          year: detail.yearName
        }
      }
      const page = this.$router.resolve({
        path: '/brandTendencyTop',
        query: query
      })
      window.open('/' + page.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.brand-title
  display flex
  justify-content space-between
  align-items center
  padding 20px
  div.see-more
    border 1px solid #FFA134
    color #FFA134
    font-size 12px
    line-height 23px
    text-align center
    width 70px
.brand-top
  background-color #272930
.tendency-title
  position relative
  font-size 18px
  color #fff
  font-weight 500
  padding-left 10px
  i
    position absolute
    left 0
    top 3px
    width 2px
    height 18px
    background #ffa134
.brand-top-content
  border-top 1px solid #424242
  padding 20px 40px 20px 28px
  h3
    color #ffffff
    font-size 16px
    font-weight 500
    line-height 22px
    margin-bottom 6px
  p
    color #7D7E82
    line-height 20px
    font-size 12px
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
.brand-top-list
  display flex
  .list-item
    width 225px
    box-sizing border-box
    display flex
    align-items center
    justify-content center
    color #ffffff
    font-size 16px
    text-align center
    line-height 22px
    padding 0 22px
.h-120
  height 120px
.bg-left
  // background:linear-gradient(134deg,rgba(58,127,237,.2) 0%,rgba(200,235,255,.2) 100%);
  background url('~@/assets/images/public/brand-top1.png') no-repeat
  background-size cover
.bg-right-one
  background url('~@/assets/images/public/brand-top2.png') no-repeat
  background-size cover
  // background:linear-gradient(134deg,rgba(252,91,98,.2) 0%,rgba(251,124,92,.2) 100%);
.bg-right-two
  background url('~@/assets/images/public/brand-top3.png') no-repeat
  background-size cover
  // background:linear-gradient(134deg,rgba(254,149,59,.2) 0%,rgba(255,190,54,.2) 100%);
</style>
