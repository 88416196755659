import { render, staticRenderFns } from "./brandTop.vue?vue&type=template&id=ab0e1134&scoped=true&"
import script from "./brandTop.vue?vue&type=script&lang=js&"
export * from "./brandTop.vue?vue&type=script&lang=js&"
import style0 from "./brandTop.vue?vue&type=style&index=0&id=ab0e1134&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab0e1134",
  null
  
)

export default component.exports